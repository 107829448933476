
.mainDiv{
    display:flex;
    justify-content: center;
    align-items: center;
    background-image: url('./home.jpg') ;
    background-size:cover;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    
}
.texto{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color:white;
    gap:1rem;
    font-size:20px;
    font-family:'blair-itc-medium';
}
.abogados{
    border-bottom: 4px solid white;
}
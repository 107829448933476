body {
  margin: 0;
  background-color: #DCE1E6;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.MainPage_mainDiv__10D_N{
    display:flex;
    justify-content: center;
    align-items: center;
    background-image: url(/static/media/home.3dafdae2.jpg);
    background-size:cover;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    
}
.MainPage_texto__3Dp1n{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color:white;
    grid-gap:1rem;
    gap:1rem;
    font-size:20px;
    font-family:'blair-itc-medium';
}
.MainPage_abogados__14Bw8{
    border-bottom: 4px solid white;
}
.ContactComponent_mainContact__12cxd{
    margin-top:6rem;

}

.ContactComponent_divIcons__39r8I{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:10rem;
    grid-gap:5rem;
    gap:5rem;
}

.ContactComponent_contactTitle__1V4Yp{
    margin-bottom:2rem;
}

.ContactComponent_contactIcon__2RGvC:hover{
    cursor:pointer;
}

.ContactComponent_links__v9slD{
    color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:10rem;
    grid-gap:5rem;
    gap:5rem;
}

.mainContact{
    margin-top:6rem;

}

.divIcons{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:10rem;
    gap:5rem;
}

.contactTitle{
    margin-bottom:2rem;
}

.contactIcon:hover{
    cursor:pointer;
}

.links{
    color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:10rem;
    gap:5rem;
}